// ヘッダーを変えるやつ
const header = document.querySelector('header');
const wrapper = document.querySelector('.front_wrapper');
window.addEventListener('DOMContentLoaded', function () {
  wrapper.classList.add("home");
});
$(function () {
  $(window).on("scroll", function () {
    //300以上にスクロールされた or されている時は50msかけてフェードイン/アウト
    if ($(this).scrollTop() > 0) {
      header.classList.remove("first_view_header");
    } else {
      header.classList.add("first_view_header");
    }
  });
});

//ローディングアニメーション
window.addEventListener('DOMContentLoaded', function () {
  function loaderClose() {
    const loader = document.getElementById('home_loader_wrap');
    loader.classList.add('loaded');
  }
  setTimeout(loaderClose, 2000);
});

// ファーストビューの斜線
window.addEventListener('DOMContentLoaded', function () {
  let arrayY = [];
  let arrayX = [];
  let i = 1;
  let interval = null;
  const interval_ms = 200;
  const drawSlashLine = () => {
    let positionHtml = (top, left, n) => `<div class="slash_position n${n} ${col}" style="top: ${top}%; left: ${left}%;"></div>`;
    let line = height => `<svg style="height: ${height}vh;" xmlns="http://www.w3.org/2000/svg"><line class="cls-1" x1="0.48" y1="320.5" x2="99.34" y2="0.15"/></svg>`;
    const drawArea = document.querySelector('.slash_line_outer');
    let y = 0;
    let x = 0;
    let randomNumY = 0;
    let randomNumX = 0;
    const randomRangeMin = -20;
    const randomRangeMax = 120;
    const disallowedRange = 10;
    const counterMax = 20; //DOM生成数上限

    // Y軸の数値 Math.floor( Math.random() * (max + 1 - min) ) + min; 
    randomNumY = Math.floor(Math.random() * (randomRangeMax + 1 - randomRangeMin)) + randomRangeMin;
    let rangeY = (element) => (randomNumY - disallowedRange) < element && element < (randomNumY + disallowedRange); //既存と近すぎないか判定
    if (arrayY.some(rangeY)) {
      return;
    }
    // X軸の数値 Math.floor( Math.random() * (max + 1 - min) ) + min; 
    randomNumX = Math.floor(Math.random() * (randomRangeMax + 1 - randomRangeMin)) + randomRangeMin;
    let rangeX = (element) => (randomNumX - disallowedRange) < element && element < (randomNumX + disallowedRange); //既存と近すぎないか判定
    if (arrayX.some(rangeX)) {
      return;
    }
    arrayX.push(randomNumX);
    arrayY.push(randomNumY);

    let positionSelector = document.querySelector(`.slash_position.n${i}`);
    if (positionSelector) {
      positionSelector.remove();
    }
    let col = randomNumX < 45 ? "black" : "white"
    drawArea.insertAdjacentHTML("beforeend", positionHtml(randomNumY, randomNumX, i));
    let parent = document.querySelector(`.slash_position.n${i}`);
    let lineHeight = Math.floor(Math.random() * (30 + 1 - 18)) + 18;
    parent.insertAdjacentHTML("beforeend", line(lineHeight));

    if (arrayY.length >= 5) {
      arrayY.shift();
      arrayX.shift();
    }
    if (i >= counterMax) {
      i = 0;
    }
    i++;
  };
  interval = setInterval(drawSlashLine, interval_ms);

  document.addEventListener("visibilitychange", function () {
    if (document.visibilityState == "hidden") {
      clearInterval(interval);
      document.querySelectorAll(".slash_position").forEach(el => el.remove());
      let arrayY = [];
      let arrayX = [];
      let i = 1;
    } else {
      interval = setInterval(drawSlashLine, interval_ms);
    }
  });
});